<template>
  <div>
    <div class="backgroundOV"></div>
    <v-card outlined>
      <v-card-title class="titleLarge">Hintergrundwissen</v-card-title>
    </v-card>
    <br />
    <br />
    <v-card>
      <v-card-text>
        <p>
          Das halbschriftliche Rechnen und auch das Kopfrechnen gehören zum
          sogenannten Zahlenrechnen, da mit Zahlen und nicht mit Ziffern
          gerechnet wird. Die Grundlage bildet das Ausnutzen von Zahlbeziehungen
          sowie das Einsetzen flexibler und angemessener Rechenstrategien.
          Dagegen wird bei schriftlichen Rechenstrategien ein Ziffernrechnen
          fokussiert (Padberg, Benz, 2021). Für ein sicheres und
          verständnisbezogenes Anwenden von Strategien des halbschriftlichen
          Rechnens müssen sowohl Zahl- und Operationsvorstellungen als auch das
          Stellenwertverständnis tragfähig ausgebaut sein (Förderzentrum
          Mathematik, o.J.).
        </p>
        <p>Wesentliche Kennzeichen des halbschriftlichen Rechnens sind:</p>
        <ol>
          <li>
            Es gibt, anders als beim schriftlichen Rechnen, keine fest
            vorgeschriebenen Rechenwege. Es sind verschiedene halbschriftliche
            Lösungsstrategien möglich. Welche Lösungsstrategie naheliegend ist,
            kann von der jeweiligen Aufgabe oder persönlichen Präferenzen
            abhängen.
          </li>
          <li>
            Auch die Notationsweisen sind nicht verbindlich festgelegt. Es gibt
            verschiedene Möglichkeiten der Darstellung der Vorgehensweisen.
          </li>
        </ol>
        <p>(Selter, Zannetin, 2019)</p>
        <AppLiteraturHR />
      </v-card-text>
    </v-card>
    <AppBottomNavHR
      next="/zahlenrechnen/hintergrundwissen/strategien-des-halbschriftlichen-rechnens"
      back="/zahlenrechnen"
    />
  </div>
</template>

<script>
import AppBottomNavHR from "@/common/AppBottomNavHR";
import AppLiteraturHR from "@/common/AppLiteraturHR";

export default {
  components: {
    AppBottomNavHR,
    AppLiteraturHR,
  },
};
</script>

<style scoped>
#app .titleLarge {
  font-weight: normal;
  font-size: xx-large;
}
#app .titleSmaller {
  font-weight: normal;
  font-size: x-large;
}
</style>
